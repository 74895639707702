import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { MouseEventHandler, ReactNode } from 'react';
import { PulseLoader } from 'react-spinners';

import { Colors } from '../lib/colors';

type BUTTON_TYPE = 'primary' | 'secondary' | 'third' | 'flat' | 'outlined' | 'flashing' | 'pulsing';
type BUTTON_SIZE = 'large' | 'small';
export type BUTTON_STATUS = 'enabled' | 'disabled' | 'loading';

const blinking = keyframes({
  '0%': {
    backgroundColor: Colors.red500,
  },
  '50%': {
    backgroundColor: Colors.grey500,
  },
  '100%': {
    backgroundColor: Colors.red500,
  },
});

const pulsing = keyframes({
  '0%': {
    transform: 'scale(1)',
  },
  '50%': {
    transform: 'scale(1.075)',
  },
  '100%': {
    transform: 'scale(1)',
  },
});

interface Props {
  buttonType: BUTTON_TYPE;
  children: ReactNode;
  size: BUTTON_SIZE;
  onClick: MouseEventHandler<HTMLButtonElement>;
  status?: BUTTON_STATUS;
  flexible?: boolean;
  rounded?: boolean;
  hideShadows?: boolean;
}

const COLOR_STYLES = {
  primary: {
    color: Colors.white,
    backgroundColor: Colors.black,
    boxShadow: '2px 5px 10px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      backgroundColor: Colors.grey900,
      boxShadow: '2px 5px 10px rgba(0, 0, 0, 0.4)',
    },
    '&:disabled': {
      color: Colors.grey200,
      backgroundColor: Colors.grey300,
      cursor: 'auto',
    },
  },
  secondary: {
    color: Colors.white,
    backgroundColor: Colors.grey300,
    boxShadow: '2px 5px 10px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      backgroundColor: Colors.grey500,
      boxShadow: '2px 5px 10px rgba(0, 0, 0, 0.4)',
    },
    '&:disabled': {
      color: Colors.grey200,
      backgroundColor: Colors.grey300,
      cursor: 'auto',
    },
  },
  third: {
    color: Colors.white,
    backgroundColor: Colors.bluegrey700,
    boxShadow: '2px 5px 10px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      backgroundColor: Colors.bluegrey900,
      boxShadow: '2px 5px 10px rgba(0, 0, 0, 0.4)',
    },
    '&:disabled': {
      color: Colors.grey200,
      backgroundColor: Colors.grey300,
      cursor: 'auto',
    },
  },
  flat: {
    color: Colors.black,
    backgroundColor: 'transparent',
    '&:disabled': {
      cursor: 'auto',
    },
  },
  outlined: {
    color: Colors.black,
    backgroundColor: 'transparent',
    border: `1px solid ${Colors.black}`,
    '&:hover': {
      backgroundColor: Colors.grey100,
      boxShadow: '2px 5px 10px rgba(0, 0, 0, 0.25)',
    },
    '&:disabled': { color: Colors.grey500, border: `1px solid ${Colors.grey500}`, cursor: 'auto' },
  },
  flashing: {
    color: Colors.grey200,
    backgroundColor: Colors.red500,
    animation: `${blinking} 1s infinite`,
  },
  pulsing: {
    color: Colors.white,
    backgroundColor: Colors.black,
    animation: `${pulsing} 1.25s infinite`,
  },
};

const SIZE_STYLES = {
  large: {
    height: '52px',
    padding: '0px 24px',

    /* LABEL_LG */
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  small: {
    height: '36px',
    padding: '1px 12px 0px',
    /* LABEL_SM */
    fontWeight: '500',
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '0.5px',
  },
};

const LOADER_STYLES = {
  large: {
    marginTop: '4px',
  },
  small: {
    marginTop: '2px',
  },
};

const LOADER_COLORS = {
  primary: Colors.white,
  secondary: Colors.white,
  third: Colors.white,
  flat: Colors.grey900,
  outlined: Colors.grey900,
  flashing: Colors.white,
  pulsing: Colors.white,
};

const ButtonElement = styled.button(
  ({ buttonType, size, status = 'enabled', flexible = false, rounded = false, hideShadows = false }: Props) =>
    css({
      fontFamily: 'Spartan, sans-serif',
      width: flexible ? '100%' : 'auto',
      border: 'none',
      borderRadius: rounded ? '50px' : '4px',
      cursor: 'pointer',
      textTransform: 'none',
      transition: '0.2s',
      pointerEvents: ['loading', 'disabled'].includes(status) ? 'none' : 'auto',
      ...COLOR_STYLES[buttonType],
      ...SIZE_STYLES[size],
      ...(hideShadows
        ? {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          }
        : {}),
    })
);

interface LoaderContainerProps {
  size: BUTTON_SIZE;
}

const LoaderContainer = styled.div(({ size }: LoaderContainerProps) => ({
  ...LOADER_STYLES[size],
}));

export const CocoButtonV2 = (props: Props) => {
  const content =
    props.status === 'loading' ? (
      <LoaderContainer size={props.size}>
        <PulseLoader color={LOADER_COLORS[props.buttonType]} size={10} />
      </LoaderContainer>
    ) : (
      props.children
    );

  return (
    <ButtonElement {...props} disabled={props.status === 'disabled'}>
      {content}
    </ButtonElement>
  );
};
