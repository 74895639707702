import { UserRole } from '@coco/types/auth';
import { AppRoute, AppRoutes, AppRoutesV3, RouteConfig } from '@coco/types/mission-control';

const allRoutes: AppRoute[] = [...Object.values(AppRoutes), ...Object.values(AppRoutesV3)];
const allRoutesConfig: RouteConfig[] = allRoutes.map((route) =>
  route === AppRoutes.DELIVERIES ? { path: route, isHome: true } : { path: route }
);

// if the user has at least one path in its config, it should specify a home route
export const ROUTE_CONFIG_MAP: Record<UserRole, Array<RouteConfig>> = {
  [UserRole.Admin]: allRoutesConfig,
  [UserRole.Engineering]: allRoutesConfig,
  [UserRole.Dispatch]: [
    { path: AppRoutes.DELIVERIES, isHome: true },
    { path: AppRoutes.DELIVERY_DETAIL },
    { path: AppRoutes.FIELD_OPS },
    { path: AppRoutes.FLEET },
    { path: AppRoutes.PILOTS },
    { path: AppRoutes.QA },
    { path: AppRoutes.DEPLOYMENTS },
    { path: AppRoutes.DEPLOYMENTS_PARKING_LOTS },
    { path: AppRoutes.DEPLOYMENTS_DEPLOYMENT_TRIPS },
    { path: AppRoutes.DEPLOYMENTS_STORAGE_LOCATIONS },
    { path: AppRoutes.DEPLOYMENTS_FLEET },
    { path: AppRoutes.DEPLOYMENTS_MERCHANT_IMAGES },
    { path: AppRoutes.TRIPS },
    { path: AppRoutesV3.MERCHANTS },
  ],
  [UserRole.OpsAdmin]: [
    { path: AppRoutes.DELIVERIES, isHome: true },
    { path: AppRoutes.DELIVERY_DETAIL },
    { path: AppRoutes.FIELD_OPS },
    { path: AppRoutes.FLEET },
    { path: AppRoutes.QA },
    { path: AppRoutes.PILOTS },
    { path: AppRoutes.DEPLOYMENTS },
    { path: AppRoutes.DEPLOYMENTS_PARKING_LOTS },
    { path: AppRoutes.DEPLOYMENTS_DEPLOYMENT_TRIPS },
    { path: AppRoutes.DEPLOYMENTS_STORAGE_LOCATIONS },
    { path: AppRoutes.DEPLOYMENTS_FLEET },
    { path: AppRoutes.DEPLOYMENTS_MERCHANT_IMAGES },
    { path: AppRoutes.TRIPS },
    { path: AppRoutesV3.MERCHANTS },
  ],
  [UserRole.Support]: [
    { path: AppRoutes.DELIVERIES, isHome: true },
    { path: AppRoutes.DELIVERY_DETAIL },
    { path: AppRoutes.QA },
    { path: AppRoutes.TRIPS },
  ],
  [UserRole.Pilots]: [],
  [UserRole.FieldOps]: [],
  [UserRole.Merchants]: [],
  [UserRole.OpsBetaTester]: [],
};

export const getAllowedRoutes = (userRoles: UserRole[]): AppRoute[] => {
  return userRoles.reduce((acc: AppRoute[], curr: UserRole) => {
    const configPaths = ROUTE_CONFIG_MAP[curr]?.map((config) => config.path) ?? [];
    acc = [...acc, ...configPaths];
    return acc;
  }, []);
};

export const getHomeRoute = (userRoles: UserRole[]): AppRoute | null => {
  for (const role of userRoles) {
    const homeRoute = ROUTE_CONFIG_MAP[role]?.find((config) => config.isHome);
    if (homeRoute) return homeRoute.path;
  }
  return null;
};

export const sidebarItemsData: {
  iconName: string; // we access these icons from `${CDN_URL}/images/${iconName}`
  name: string;
  route: AppRoute;
}[] = [
  {
    iconName: 'bell.svg',
    name: 'Deliveries',
    route: AppRoutes.DELIVERIES,
  },
  {
    iconName: 'food-bag.svg',
    name: 'QA',
    route: AppRoutes.QA,
  },
  {
    iconName: 'controller.svg',
    name: 'Pilots',
    route: AppRoutes.PILOTS,
  },
  {
    iconName: 'fleet.svg',
    name: 'Fleet',
    route: AppRoutes.FLEET,
  },
  {
    iconName: 'person.svg',
    name: 'Field Ops',
    route: AppRoutes.FIELD_OPS,
  },
  {
    iconName: 'parking-lot-2-icon.png',
    name: 'Deployments',
    route: AppRoutes.DEPLOYMENTS,
  },
  {
    iconName: 'dog.svg',
    name: 'Watchdog',
    route: AppRoutes.WATCHDOG,
  },
  {
    iconName: 'storefront.svg',
    name: 'Merchants',
    route: AppRoutesV3.MERCHANTS,
  },
];
