import { User } from '@auth0/auth0-react';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  Tooltip,
} from '@mui/material';
import { useState } from 'react';

interface SidebarLayoutProps {
  sidebarItems: SidebarItem[];
  user?: User;
  handleLogout?: () => void;
  width?: string;
  children: React.ReactNode;
  sx?: SxProps;
  hide?: boolean;
}

export interface SidebarItem {
  name: string;
  onClick: () => void;
  iconUrl: string;
  isActive: boolean;
}

export function SidebarLayout({
  sidebarItems,
  user,
  handleLogout,
  width = '63px',
  children,
  sx,
  hide = false,
}: SidebarLayoutProps) {
  const [logoutAnchorEl, setLogoutAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <Box sx={{ display: 'flex', height: '100vh', overflow: 'hide', ...sx }}>
      <Box component="nav" sx={{ width, boxShadow: 'inset -1px 0px 0px #F0F0F0', display: hide ? 'none' : 'block' }}>
        <List sx={{ height: '80%' }}>
          {sidebarItems.map((item) => (
            <Tooltip key={item.name} title={item.name} placement="right">
              <ListItemButton
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingX: '8px',
                  backgroundColor: item.isActive ? '#F0F0F0' : '',
                }}
                onClick={item.onClick}
              >
                {!!item.iconUrl ? (
                  <ListItemIcon
                    sx={{
                      minWidth: '32px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <img src={item.iconUrl} alt={item.name} width="20px" height="20px" />
                  </ListItemIcon>
                ) : (
                  item.name
                )}
              </ListItemButton>
            </Tooltip>
          ))}
        </List>

        {!!user && !!handleLogout && (
          <Box
            sx={{
              height: '20%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
              paddingBottom: '24px',
            }}
          >
            <Stack spacing={3}>
              <Box>
                <Tooltip title={user.name ?? user.email} placement="right">
                  <IconButton onClick={(e) => setLogoutAnchorEl(e.currentTarget)} size="small">
                    <Avatar
                      sx={{
                        width: '32px',
                        height: '32px',
                      }}
                    >
                      {user && user.name?.[0].toLocaleUpperCase()}
                    </Avatar>
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={logoutAnchorEl}
                  open={logoutAnchorEl !== null}
                  onClose={() => setLogoutAnchorEl(null)}
                  elevation={0}
                  sx={{
                    filter: 'drop-shadow(0px 2px 2px rgba(0,0,0,0.2))',
                  }}
                >
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            </Stack>
          </Box>
        )}
      </Box>

      {/* Page Content */}
      <Box component="main" sx={{ flex: 1, display: 'flex' }}>
        {children}
      </Box>
    </Box>
  );
}
