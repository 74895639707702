import styled from '@emotion/styled';
import LoadingButton from '@mui/lab/LoadingButton';

import { Colors } from '../lib/colors';

type BUTTON_TYPE = 'primary' | 'secondary' | 'third' | 'flat';
type BUTTON_SIZE = 'large';

interface Props {
  buttonType: BUTTON_TYPE;
  size: BUTTON_SIZE;
  isFlexible?: boolean;
}

const COLOR_STYLES = {
  primary: {
    color: Colors.white,
    backgroundColor: Colors.black,
    '&:hover': {
      backgroundColor: Colors.grey900,
    },
  },
  secondary: {
    color: Colors.white,
    backgroundColor: Colors.grey300,
    '&:hover': {
      backgroundColor: Colors.grey500,
    },
  },
  third: {
    color: Colors.white,
    backgroundColor: Colors.bluegrey700,
    '&:hover': {
      backgroundColor: Colors.bluegrey900,
    },
  },
  flat: {
    color: Colors.black,
    backgroundColor: Colors.white,
  },
};

const SIZE_STYLES = {
  large: {
    height: '52px',

    /* LABEL_LG */
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
};

export const CocoButton = styled(LoadingButton)`
  ${({ buttonType = 'primary', size = 'large', isFlexible = false }: Props) => ({
    fontFamily: 'Spartan',
    width: isFlexible ? '100%' : 'auto',
    border: 'none',
    cursor: 'pointer',
    textTransform: 'none' as const,
    ...COLOR_STYLES[buttonType],
    ...SIZE_STYLES[size],
    '&:disabled': {
      color: Colors.white,
      backgroundColor: Colors.grey300,
    },
  })}
`;
